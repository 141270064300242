import styled from "@emotion/styled";

export const Container = styled.aside`
  width: 100%;
  // overflow-y: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;

  position: sticky;
  top: 0;
  transition: transform 0.5s;
  border-right: 1px solid #d1d3d4;
  // height: calc(100vh - 1px);

  nav {
    width: 100%;
    padding-top: 24px;
    align-self: flex-start;
    flex: 1;
    @media (max-width: 780px) {
      padding-left: 24px;
    }
  }

  @media (max-width: 780px) {
    max-width: 75%;
    min-width: auto;
    z-index: 1001;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    padding-top: 0;
    height: 100%;
    overflow-y: auto;
    background: ${({ theme }) => theme.colors.background};
    transform: translate3d(
      ${({ isMenuOpen }) => (isMenuOpen ? "0" : "-100%")},
      0,
      0
    );
  }
`;

export const LogoContainer = styled.div`
  width: 100%;

  a {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
`;

export const List = styled.ul`
  list-style: none;
  padding-left: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
`;

export const Heading = styled.li`
  cursor: pointer;
  width: 100%;
  text-transform: inherit;
  font-size: 18px;
  font-weight: 500;
  margin-top: 16px;
  color: ${({ theme }) => theme.colors.title};
  // background-image: url("data:image/svg+xml,%0A%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.1875 4.375L12.8125 10L7.1875 15.625' stroke='%23757A7E' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  // background-repeat: no-repeat;
  // background-position: 0;
  // &.active {
  //   margin-bottom: 12px;
  //   background-image: url("data:image/svg+xml,%0A%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15.625 7.1875L10 12.8125L4.375 7.1875' stroke='%23757A7E' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  //   & + ul {
  //     display: flex;
  //   }
  // }
  // & + ul {
  //   display: none;
  // }
`;

export const Item = styled.li`
  width: calc(100% - 16px);
  margin: 0 16px 0 0;
  font-size: 16px;
  transition: all 200ms ease-in-out;
  padding: 0;
  cursor: pointer;
  a,
  span {
    display: block;
    font-size: 16px;
    color: ${({ theme }) => theme.colors.text};
    background-color: ${({ theme }) => theme.colors.background};
    padding: 1px 16px 1px 0;
    // border-left: 1px solid #d1d3d4;
    margin: 4px 0;
    border-radius: 4px;
    font-weight: normal;

    text-decoration: none;
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: flex-start;
    align-items: center;

    cursor: pointer;
    margin: 0 auto;

    transition: all 0.2s ease;

    svg {
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }

    &:not(.active-link):hover {
      color: ${({ theme }) => theme.colors.primary};
    }

    &.active-link {
      font-weight: 500;
      color: ${({ theme }) => theme.colors.primary};
    }
  }
`;

export const SubItem = styled(List)`
  margin: 0;
  width: 100%;
`;
