import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { GiHamburgerMenu } from "react-icons/gi";
import { useStaticQuery, graphql, Link } from "gatsby";
import Logo from "./Logo";
import { LogoContainer } from "./Sidebar/styles";
const Container = styled.header`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid #d1d3d4;
  min-height: 70px;
  align-items: center;
  position: fixed;
  width: 100%;
  background-color: #fff;
  top: 0;
  z-index: 999;
  h2 {
    margin: 0;
    border: none;
    padding: 0;
    font-size: 18px;
    @media (min-width: 780px) {
      display: none;
    }
    @media (max-width: 359px) {
      font-size: 14px;
    }
  }

  button {
    border: none;
    background: none;
    cursor: pointer;
    margin-right: 16px;
    margin-left: 24px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    @media (min-width: 780px) {
      display: none;
    }
  }
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0 32px;
    @media (max-width: 780px) {
      padding: 0 24px 0 0;
    }
    a.btn-primary {
      color: #fff;
      background-color: #f15e22;
      box-shadow: none;
      transition: 0.3s ease-in 0s;
      background-size: 250% auto;
      background-image: linear-gradient(
        -85deg,
        #f15e22 0%,
        #f15e22 40%,
        rgba(255, 255, 255, 0.2) 50%,
        #f15e22 60%,
        #f15e22 100%
      );
      display: inline-block;
      border: 2px solid #f15e22;
      padding: 12px 24px;
      border-radius: 4px;
      font-size: 18px;
      font-weight: 600;
      line-height: 1.2;
      text-transform: uppercase;
      &:hover {
        background-position: right center;
        color: #fff;
      }
    }
    ul {
      list-style: none;
      display: flex;
      align-items: center;
      margin: 0;
      padding: 0;
      @media (max-width: 580px) {
        display: none;
      }
      li {
        &:not(:last-child) {
          margin-right: 24px;
        }
        a {
          font-size: 18px;
          font-weight: 500;
          &:hover {
            color: #008ecf;
            text-decoration: none;
          }
        }
      }
    }
  }
`;

export default function Header({ handleMenuOpen }) {
  const {
    site: {
      siteMetadata: { basePath },
    },
  } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            basePath
          }
        }
      }
    `
  );

  return (
    <Container>
      <button aria-label="Open sidebar" type="button" onClick={handleMenuOpen}>
        <GiHamburgerMenu size={23} aria-hidden="true" />
      </button>
      <div className="header">
        <div className="logo">
          <LogoContainer>
            <Link to={basePath} aria-label="Go to home page">
              <Logo aria-hidden="true" />
            </Link>
          </LogoContainer>
        </div>
        <div className="nav">
          <nav>
            <ul>
              <li>
                <Link to={"https://www.loginradius.com/docs/api/v2/announcements/cli/"}> Manual </Link>
              </li>
              <li>
                <a
                  href="https://github.com/LoginRadius/lr-cli/releases/latest"
                  target="_blank"
                >
                  Release notes
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </Container>
  );
}

Header.propTypes = {
  handleMenuOpen: PropTypes.func.isRequired,
};
